import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import Accordion from '../svg/icons/accordion.svg'
import Beer from '../svg/icons/beer.svg'
import Bottle from '../svg/icons/bottle.svg'
import Cactus from '../svg/icons/cactus.svg'
import Confetti from '../svg/icons/confetti.svg'
import Fourteen from '../svg/icons/fourteen.svg'
import Header from '../svg/icons/heart.svg'
import Key from '../svg/icons/key.svg'
import Party from '../svg/icons/party.svg'
import Pretzel from '../svg/icons/pretzel.svg'
import Sausage from '../svg/icons/sausage.svg'
import Sunglasses from '../svg/icons/sunglasses.svg'

import './Icon.css'

export const iconList = [
  'accordion',
  'beer',
  'bottle',
  'cactus',
  'confetti',
  'fourteen',
  'header',
  'key',
  'party',
  'pretzel',
  'sausage',
  'sunglasses'
]

const Icon = ({ id, ...props }) => {
  const renderIcon = id => {
    let result

    switch (id) {
      case 'accordion':
        result = <Accordion />
        break
      case 'beer':
        result = <Beer />
        break
      case 'bottle':
        result = <Bottle />
        break
      case 'cactus':
        result = <Cactus />
        break
      case 'confetti':
        result = <Confetti />
        break
      case 'fourteen':
        result = <Fourteen />
        break
      case 'header':
        result = <Header />
        break
      case 'key':
        result = <Key />
        break
      case 'party':
        result = <Party />
        break
      case 'pretzel':
        result = <Pretzel />
        break
      case 'sausage':
        result = <Sausage />
        break
      case 'sunglasses':
        result = <Sunglasses />
        break
      default:
        result = <></>
        break
    }

    return result
  }

  return (
    <motion.div
      drag={true}
      className="iconItem"
      {...props}
    >
      {renderIcon(id)}
    </motion.div>
  )
}

Icon.propTypes = {
  id: PropTypes.oneOf(iconList)
}

export default Icon
