import React, { useEffect, useState, useCallback } from 'react'

import SEO from '../components/SEO'
import HeroText from '../components/HeroText'
import HeroWaves from '../components/HeroWaves'
import HeroCTA from '../components/HeroCTA'
import InviteDetails from '../components/InviteDetails'
import BackgroundIcons from '../components/BackgroundIcons'

const IndexPage = () => {
  // Track size and position of hero
  const [heroDimensions, setHeroDimensions] = useState()
  const heroCallbackRef = useCallback(node => {
    setHeroDimensions(node)
  }, [])

  const [runAnimation, setRunAnimation] = useState('centred')

  useEffect(() => {
    window.scrollTo(0, 0)

    document && document.documentElement.classList.add('loading')

    setTimeout(() => {
      document && document.documentElement.classList.add('loaded')
    }, 6500)

    setTimeout(() => {
      setRunAnimation('explode')
    }, 4000)
  }, [])

  return (
    <div className="IndexPage">
      <BackgroundIcons
        heroDimensions={heroDimensions}
        animate={runAnimation}
      />
      <SEO />
      <div className="Hero">
        <HeroText objRef={heroCallbackRef} />
        <HeroWaves />
        <HeroCTA />
      </div>
      <div className="Content">
        <InviteDetails />
      </div>
    </div>
  )
}

export default IndexPage
