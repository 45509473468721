import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Icon, { iconList } from './Icon'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { motion, useViewportScroll, useTransform } from 'framer-motion'

import './BackgroundIcons.css'

const iconSize = {
  width: 42,
  height: 42
}

const iconBaseStyle = {
  position: 'fixed',
  ...iconSize
}

const iconVariantsCentred = {
  centred: {
    left: '50%',
    top: '50%',
    x: '-50%',
    y: '-50%',
    opacity: 0
  }
}

const iconVariantsExplode = {
  opacity: 1
}

const isContainedIn = (element, container) => {
  const halfWidth = container.width / 2
  const halfHeight = container.height / 2

  return (
    element.top >= (container.top - halfHeight) &&
    (element.top + element.height) <= ((container.top - halfHeight) + container.height) &&
    element.left >= (container.left - halfWidth) &&
    (element.left + element.width) <= ((container.left - halfWidth) + container.width)
  )
}

const responsiveValue = (windowWidth, defaultValue, tablet, mobile) => {
  const isTabletScreen = windowWidth <= 1200
  const isMobileScreen = windowWidth <= 800

  return (
    isTabletScreen
      ? isMobileScreen
        ? mobile
        : tablet
      : defaultValue
  )
}

const calculateGridPositions = (iconSize, windowWidth, windowHeight, heroDimensions) => {
  const spacing = responsiveValue(windowWidth, 130, 100, 50)

  const positions = []
  let offset = false
  let count = Math.floor(Math.random() * 10)
  let dimensions = { left: 0, top: 0, width: 0, height: 0 }

  if (heroDimensions) {
    dimensions = {
      top: heroDimensions.offsetTop - responsiveValue(windowWidth, 50, 0, 0),
      left: heroDimensions.offsetLeft - responsiveValue(windowWidth, 50, 20, 20),
      height: heroDimensions.scrollHeight + responsiveValue(windowWidth, 100, 120, 80),
      width: heroDimensions.scrollWidth + responsiveValue(windowWidth, 100, 150, 100)
    }
  }

  for (let x = 10; x < windowWidth; x += (iconSize.width + spacing)) {
    // Offset every second row
    offset = !offset
    if (x === 10) count++

    for (let y = 20; y < windowHeight; y += (iconSize.height + spacing)) {
      const currentTop = y + (offset ? spacing : 0) + (Math.floor(Math.random() * 40) - 20)
      const currentLeft = x + (Math.floor(Math.random() * 40) - 20)

      count++ // increase count here as well

      // Add position object
      positions.push({
        position: {
          ...iconVariantsExplode,
          x: `calc(${currentLeft}px - 50vw)`,
          y: `calc(${currentTop}px - 50vh)`
        },
        style: {
          opacity: isContainedIn({ top: currentTop, left: currentLeft, width: iconSize.width, height: iconSize.height }, dimensions) ? 0 : 1,
          // scale: 1.01,
          transition: {
            // ease: 'easeOut',
            // duration: Math.random() * 1.8
            type: 'spring',
            stiffness: 20 + Math.random() * 40,
            damping: 4,
            restSpeed: 0.01,
            restDelta: 0.01
          }
        },
        id: iconList[(count + Math.floor(Math.random() * 6)) % iconList.length]
      }
      )
    }
  }

  return positions
}

const BackgroundPattern = ({ heroDimensions, ...props }) => {
  const window = useWindowDimensions()
  const [gridPositions, setGridPositions] = useState([])

  useEffect(() => {
    // Update gridPositions when window is resized
    setGridPositions(calculateGridPositions(iconSize, window.width, window.height, heroDimensions))
  }, [window.width])

  const { scrollYProgress } = useViewportScroll()
  const y = responsiveValue(window.width, useTransform(scrollYProgress, [0, 1], ['2vh', '-8vh']), useTransform(scrollYProgress, [0, 1], ['-1vh', '-11vh']), useTransform(scrollYProgress, [0, 1], ['0', '-10vh']))

  return (
    <motion.div
      className="BackgroundIcons"
      style={{
        y
      }}
      test={gridPositions}
    >
      {
        gridPositions.map((element, index) => {
          const icon = (
            <Icon
              className="BackgroundIcon"
              id={element.id}
              style={iconBaseStyle}
              variants={{
                ...iconVariantsCentred,
                explode: {
                  ...element.position,
                  ...element.style
                }
              }}
              initial="centred"
              key={`${window.width}-${index}-${element.id}`} // rerender on every window.wi
              {...props}
            />
          )
          return icon
        })
      }
    </motion.div>
  )
}

BackgroundPattern.propTypes = {
  heroDimensions: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ])
}

export default BackgroundPattern
