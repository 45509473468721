import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class ValidationForm extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func,
    className: PropTypes.string
  }

  state = {
    showErrors: false
  }

  handleSubmit (e) {
    // Use HTML5 API to check validity (default to no client side validation)
    const isValid = this.formEl.checkValidity ? this.formEl.checkValidity() : true

    // call parent onSubmit
    const { onSubmit } = this.props
    onSubmit && onSubmit(isValid)

    this.setState({ showErrors: true })

    // scroll to first invalid input
    if (!isValid) {
      e.preventDefault()
      const formLength = this.formEl.length
      for (let i = 0; i < formLength; i++) {
        const elem = this.formEl[i]
        if (elem.nodeName.toLowerCase() !== 'button') {
          if (elem.validity && !elem.validity.valid) {
            elem.scrollIntoView && elem.scrollIntoView({ behavior: 'smooth' })
            break
          }
        }
      }
    }
  }

  render () {
    const { onSubmit, children, className, ...props } = this.props
    const { showErrors } = this.state
    return (
      <form
        ref={form => this.formEl = form}
        onSubmit={this.handleSubmit.bind(this)}
        noValidate
        className={showErrors ? 'validate ' + className : className}
        {...props}
      >
        {children}
      </form>
    )
  }
}

export default ValidationForm
