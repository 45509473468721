import { useState, useEffect } from 'react'

export function getWindowDimensions () {
  if (typeof (window) === 'undefined') {
    return { width: 0, height: 0 }
  }

  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

export default function useWindowDimensions () {
  const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 })
  let debounce

  useEffect(() => {
    function handleResize () {
      // debounce resize event for reducing calculations and explosion animations
      clearTimeout(debounce)
      debounce = setTimeout(() => setWindowDimensions(getWindowDimensions()), 100)
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
