import React, { useState, useEffect, useRef } from 'react'
import useWindowDimensions from '../hooks/useWindowDimensions'

import {
  motion,
  useViewportScroll,
  useTransform,
  useMotionValue,
  useSpring
} from 'framer-motion'

import './HeroCTA.css'

const HeroCTA = () => {
  const windowSize = useWindowDimensions()

  const buttonRef = useRef(null)
  const [hideCTA, setHideCTA] = useState(false)
  const [animateScroll, setAnimateScroll] = useState(false)
  const { scrollY } = useViewportScroll()
  const scrollTarget = useMotionValue(0)

  // Scroll animation spring
  const spring = useSpring(scrollTarget, {
    stiffness: 10,
    damping: 14,
    mass: 1,
    velocity: 0,
    restSpeed: 30, // 0.1
    restDelta: 20 // 0.01
  })

  // update scroll value on window scroll
  useEffect(() => {
    console.log('scroll', scrollY.get())

    const unsubscribe = scrollY.onChange(value => {
      if (!animateScroll) {
        scrollTarget.set(scrollY.get())
      }
      const hide = animateScroll || scrollY.get() > (windowSize.height * 0.2)
      setHideCTA(hide)
    })
    return () => {
      unsubscribe()
    }
  }, [animateScroll, scrollY, scrollTarget])

  // Scroll window on each spring animation frame
  useEffect(() => {
    const unsubscribe = spring.onChange(value => {
      if (value === scrollTarget.get()) {
        return setAnimateScroll(false)
      }
      if (animateScroll) {
        window.scrollTo(0, value)
      }
    })
    return () => {
      unsubscribe()
    }
  }, [animateScroll, spring, scrollTarget])

  // hover state
  const x = useMotionValue(0)
  const sx = useSpring(x, {
    // stiffness: 400,
    // damping: 90,
    stiffness: 1000,
    damping: 2000,
    velocity: 0
  })
  // const y = useMotionValue(0);
  const polygon = useTransform(
    sx,
    [0, 1.5],
    [
      'polygon(0% 5%, 100% 0%, 95% 100%, 0 100%)',
      'polygon(10% 0%, 100% 0%, 100% 90%, 0 100%)'
      // `polygon(0% 0%, 100% 0%, 90% 100%, 0 90%)`,
    ]
  )

  // const snap = useAnimation()

  // const [hover, setHover] = useState()

  // const left = currentTab.current.offsetLeft

  return (
    <motion.a
      className={'CTA'}
      href="#rsvp"
      onClick={(e) => {
        e.preventDefault()
        setAnimateScroll(true)
        scrollTarget.set(windowSize.height * 1.2)
      }}
      initial={{ scale: 1.01 }}
      whileHover={{ scale: 1.2 }}
      // onHoverStart={(e, { point }) => {
      //     console.log("hover", e, point.x, buttonRef.current.clientWidth);
      // }}
      // onHoverEnd={() => {

      //     // x.set(0)
      //     console.log('snap!')
      //     // snap.start({
      //     //     clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0 100%)`,
      //     //     transition: { duration: 0.5 },
      //     // })
      // }}
      onMouseMove={(e, i) => {
        // mouseInfo.x = e.clientX
        // mouseInfo.y = e.clientY

        const leftX =
              buttonRef.current.offsetParent.offsetLeft +
              buttonRef.current.offsetLeft
        const topY =
              buttonRef.current.offsetParent.offsetTop +
              buttonRef.current.offsetTop
        const width = buttonRef.current.clientWidth
        const height = buttonRef.current.clientHeight

        const dX = (e.clientX - leftX) / width
        const dY = (e.clientY - topY) / height
        x.set(dX * dX + dY * dY)
        // console.log(leftX, dX)
      }}
    >

      <div
        ref={buttonRef} className={hideCTA ? 'CTAcontent hide' : 'CTAcontent'}>
        <motion.div
          className={'CTAbg'}
          style={{ clipPath: polygon }}
        ></motion.div>
        <span className="CTAtext">Tell me more!</span>
      </div>
    </motion.a>
  )
}

export default HeroCTA
