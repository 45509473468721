import React, { useState, useLayoutEffect } from 'react'
import useWindowDimensions from '../hooks/useWindowDimensions'

import {
  motion,
  useViewportScroll,
  useTransform
} from 'framer-motion'

import Wave1 from '../svg/wave1.svg'
import Wave2 from '../svg/wave2.svg'

import './HeroWaves.css'

const HeroWaves = () => {
  const windowSize = useWindowDimensions()
  const [viewportHeight, setViewportHeight] = useState(windowSize.height)
  const { scrollY } = useViewportScroll()
  const scale1 = useTransform(scrollY, [viewportHeight * 0.2, viewportHeight * 0.6, viewportHeight], [0.5, 1.01, 0.2])
  const y = useTransform(scrollY, [0, viewportHeight * 1.5], ['195h', '-80vh'])
  const x = useTransform(scrollY, [0, viewportHeight * 0.7], ['0vw', '-20vw'])

  const scale2 = useTransform(scrollY, [0, viewportHeight * 0.6, viewportHeight], [0.4, 1.01, 1.01])
  const y2 = useTransform(scrollY, [0, viewportHeight * 2], ['200vh', '-100vh'])
  const x2 = useTransform(scrollY, [0, viewportHeight * 0.5], ['0vw', '20vw'])

  // scale1 = useSpring(scale1, {
  //     // stiffness: 100,
  //     // damping: 30,
  //     stiffness: 100,
  //     damping: 10,
  //     velocity: 0
  //  })

  useLayoutEffect(() => {
    setViewportHeight(windowSize.height)
  }, [windowSize.width])

  return (
    <div className="Waves">
      <motion.div
        className="Wave Wave2"
        style={{
          y: y2,
          x: x2
        }}
      >
        <motion.div className="WaveTip" style={{ scaleY: scale2, originY: 1 }}>
          <Wave2 />
        </motion.div>
      </motion.div>

      <motion.div
        className="Wave Wave1"
        style={{
          y,
          x
        }}
      >
        <motion.div className="WaveTip" style={{ scaleY: scale1, originY: 1 }}>
          <Wave1 />

        </motion.div>
      </motion.div>
    </div>
  )
}

export default HeroWaves
