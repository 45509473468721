import React from 'react'
import PropTypes from 'prop-types'

import N3_1 from '../svg/N31.svg'

import A1 from '../svg/A1.svg'
import A2 from '../svg/A2.svg'
import A3 from '../svg/A3.svg'
import B1 from '../svg/B1.svg'
import B2 from '../svg/B2.svg'
import B3 from '../svg/B3.svg'
import C1 from '../svg/C1.svg'
import C2 from '../svg/C2.svg'
import C3 from '../svg/C3.svg'
import D1 from '../svg/D1.svg'
import D2 from '../svg/D2.svg'
import D3 from '../svg/D3.svg'
import E1 from '../svg/E1.svg'
import E2 from '../svg/E2.svg'
import E3 from '../svg/E3.svg'
import F1 from '../svg/F1.svg'
import F2 from '../svg/F2.svg'
import F3 from '../svg/F3.svg'
import G1 from '../svg/G1.svg'
import G2 from '../svg/G2.svg'
import G3 from '../svg/G3.svg'
import H1 from '../svg/H1.svg'
import H2 from '../svg/H2.svg'
import H3 from '../svg/H3.svg'
import I1 from '../svg/I1.svg'
import I2 from '../svg/I2.svg'
import I3 from '../svg/I3.svg'
import J1 from '../svg/J1.svg'
import J2 from '../svg/J2.svg'
import J3 from '../svg/J3.svg'
import K1 from '../svg/K1.svg'
import K2 from '../svg/K2.svg'
import K3 from '../svg/K3.svg'
import L1 from '../svg/L1.svg'
import L2 from '../svg/L2.svg'
import L3 from '../svg/L3.svg'
import M1 from '../svg/M1.svg'
import M2 from '../svg/M2.svg'
import M3 from '../svg/M3.svg'
import N1 from '../svg/N1.svg'
import N2 from '../svg/N2.svg'
import N3 from '../svg/N3.svg'
import O1 from '../svg/O1.svg'
import O2 from '../svg/O2.svg'
import O3 from '../svg/O3.svg'
import P1 from '../svg/P1.svg'
import P2 from '../svg/P2.svg'
import P3 from '../svg/P3.svg'
import Q1 from '../svg/Q1.svg'
import Q2 from '../svg/Q2.svg'
import Q3 from '../svg/Q3.svg'
import R1 from '../svg/R1.svg'
import R2 from '../svg/R2.svg'
import R3 from '../svg/R3.svg'
import S1 from '../svg/S1.svg'
import S2 from '../svg/S2.svg'
import S3 from '../svg/S3.svg'
import T1 from '../svg/T1.svg'
import T2 from '../svg/T2.svg'
import T3 from '../svg/T3.svg'
import U1 from '../svg/U1.svg'
import U2 from '../svg/U2.svg'
import U3 from '../svg/U3.svg'
import V1 from '../svg/V1.svg'
import V2 from '../svg/V2.svg'
import V3 from '../svg/V3.svg'
import W1 from '../svg/W1.svg'
import W2 from '../svg/W2.svg'
import W3 from '../svg/W3.svg'
import X1 from '../svg/X1.svg'
import X2 from '../svg/X2.svg'
import X3 from '../svg/X3.svg'
import Y1 from '../svg/Y1.svg'
import Y2 from '../svg/Y2.svg'
import Y3 from '../svg/Y3.svg'
import Z1 from '../svg/Z1.svg'
import Z2 from '../svg/Z2.svg'
import Z3 from '../svg/Z3.svg'

import './14islandsType.css'

const alphabet = {
  3: [N3_1, N3_1, N3_1],
  A: [A1, A2, A3],
  B: [B1, B2, B3],
  C: [C1, C2, C3],
  D: [D1, D2, D3],
  E: [E1, E2, E3],
  F: [F1, F2, F3],
  G: [G1, G2, G3],
  H: [H1, H2, H3],
  I: [I1, I2, I3],
  J: [J1, J2, J3],
  K: [K1, K2, K3],
  L: [L1, L2, L3],
  M: [M1, M2, M3],
  N: [N1, N2, N3],
  O: [O1, O2, O3],
  P: [P1, P2, P3],
  Q: [Q1, Q2, Q3],
  R: [R1, R2, R3],
  S: [S1, S2, S3],
  T: [T1, T2, T3],
  U: [U1, U2, U3],
  V: [V1, V2, V3],
  W: [W1, W2, W3],
  X: [X1, X2, X3],
  Y: [Y1, Y2, Y3],
  Z: [Z1, Z2, Z3]
}
/*
const getChar = (char, count) => {
  char = char.toUpperCase()
  if (!alphabet[char]) return null
  const SVG = alphabet[char][count % 3]
  return <SVG/>
}
*/

const getCharVar = (char, variant) => {
  char = char.toUpperCase()
  if (!alphabet[char]) return null
  const SVG = alphabet[char][variant]
  return <SVG/>
}

const renderRow = (row, i, isLastRow) => {
  if (!row) return
  return (
    <div key={'row' + i} className="IslandsType-row">
      {row.split(',').map((char, i) => (
        <span key={'char' + i} className={`IslandsType-char ${char} c${i}`}>
          {char === ' ' && <span className="IslandsType-space"> </span>}
          {getCharVar(char[0], char[1])}
        </span>
      ))}
      {/* isLastRow && <span className="IslandsType-caret"></span> */}
    </div>
  )
}

// function lerp (start, end, amt){
//   return (1-amt)*start+amt*end
// }

// function map (val, in_min, in_max, out_min, out_max) {
//   return (val - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
// }

const FourteenType = ({ text = '' }) => {
  const rows = text.split('\\n')

  // const longestRow = Math.max(...rows.map(i => i.length))

  /*
  const isMaxGrid = true
  let fontSize
  if (isMaxGrid) {
    fontSize = 800/(longestRow + 1) + 'px'
  }
  else {
    fontSize = 90/(longestRow + 1) + 'vw'
  }
  */

  const fontSize = null // kkkmap(longestRow, 1, 20, 140, 70)

  return (
    <div className="IslandsType" style={{ fontSize }}>
      {
        rows.map((row, i) => renderRow(row, i, i === rows.length - 1))
      }
    </div>
  )
}
FourteenType.propTypes = {
  text: PropTypes.string
}

export default FourteenType
