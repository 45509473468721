import React, { useState, useLayoutEffect } from 'react'

import {
  motion,
  useViewportScroll,
  useTransform
} from 'framer-motion'

import Beer from '../svg/beer.svg'
import Sausage from '../svg/sausage.svg'
import One from '../svg/One.svg'
import Two from '../svg/Two.svg'
import Three from '../svg/Three.svg'
import Arrow from '../svg/arrow.svg'

import IslandsType from './14islandsType'
import ValidationForm from './ValidationForm'

import './InviteDetails.css'

const InviteDetails = () => {
  const [answer, setAnswer] = useState(false)
  const [viewportHeight, setViewportHeight] = useState(1000)
  const { scrollY } = useViewportScroll()
  const y = useTransform(scrollY, [viewportHeight * 0.5, viewportHeight * 0.75], ['30vh', '0vh'])

  useLayoutEffect(() => {
    setViewportHeight(window.innerHeight)
  }, [])

  return (
    <>
    <motion.div
      className="InviteDetails"
      style={{
        y
      }}
    >
      <div className="InviteContent">
        <p>
            We would like to invite you to an after work, a little celebration, a grand opening of our new office.
        </p>
        <p>
          With Oktoberfest being just around the corner, we stocked up on some beer <Beer /> and something tasty <Sausage /> to go with it.
        </p>
        <p>
            Come join us on <a href="/oktoberfest.ICS">3 October, 18:00h</a>, at <a href="https://goo.gl/maps/avgEX2CVfvsqAYdXA" target="_blank" rel="noopener noreferrer">Blekingegatan 14</a>.
            Bring a colleague from work, your loved one or a friendly neighbour.
        </p>
      </div>
    </motion.div>

    <div className="InviteRSVP">
      <div className="InviteContent">
        <p>Can you make it?</p>

        <div className="InviteOptions">
          <label className="typeButton">
            <input type="radio" name="rsvp" value="true" hidden onChange={() => setAnswer('yay')}/>
            <IslandsType text={'y0,a2,y0'}/>
          </label>
          <label className="typeButton">
            <input type="radio" name="rsvp" value="false" hidden onChange={() => setAnswer('nej')}/>
            <IslandsType text={'n0,e0,j0'} className="typeButton"/>
          </label>
        </div>

        {/* <ValidationForm
          name="oktoberfest"
          method="post"
          action="/tack"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          className={answer === 'yay' ? 'Form show' : 'Form'}
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="oktoberfest" />
          <p className="FormRow">
            <input type="email" name="email" placeholder="Your email" required/>
          </p>
          <p className="FormRow">
            <input type="text" name="name" placeholder="Your name" required/>
          </p>
          <fieldset className="FormRow">
            <legend>How many people?</legend>
            <label className="People One">
              <input type="radio" name="people" value="1" required />
              <One className="typeButton"/>
            </label>
            <label className="People Two">
              <input type="radio" name="people" value="2" required/>
              <Two className="typeButton"/>
            </label>
            <label className="People Three">
              <input type="radio" name="people" value="3" required/>
              <Three className="typeButton"/>
              <span className="errorMessage">Please tell us how many to expect.</span>
            </label>
          </fieldset>
          <button type="submit" className="BtnSend">Send <Arrow/></button>
        </ValidationForm> */}

        { answer === 'yay' &&
            <div className="EvenFull">
              <p>Sorry, this event is full.</p>
              <p>We like meeting new people. Please say <a href="mailto:hello@14islands.com">hello@14islands.com</a></p>
            </div>
        }

        { answer === 'nej' &&
          <img className="NejGif" src="https://media.giphy.com/media/q4ICE9wYvOwBG/giphy.gif" />
        }

      </div>
    </div>
    </>
  )
}

export default InviteDetails
