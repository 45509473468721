import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  motion,
  useViewportScroll,
  useTransform,
  AnimatePresence
} from 'framer-motion'

import IslandsType from './14islandsType'

const HeroText = ({ objRef }) => {
  const { scrollYProgress } = useViewportScroll()
  const scale = useTransform(scrollYProgress, [0, 1], [1.01, 2])
  const y = useTransform(scrollYProgress, [0, 1], ['0vh', '-10vh'])
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 1])
  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (index < 2) {
      setTimeout(() => setIndex(index + 1), 2000)
    }
  }, [index])

  return (
    <motion.div
      ref={objRef}
      style={{
        scale,
        y,
        opacity,
        position: 'relative',
        willChange: 'transform'
      }}>
      <AnimatePresence>
        {index === 0 && (
          <motion.div
            style={{
              position: 'absolute'
            }}
            originX={0}
            key={0}
            initial={{ opacity: 0, x: '-10%' }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, scaleX: 0.2, x: '-20%' }}
            transition={{ duration: 0.3, delay: 0, ease: 'anticipate' }}
          >
            <IslandsType text={'S0,a1,v0,e1, ,t1,h2,e0, ,d1,a2,t2,e0'} />
          </motion.div>
        )}
        {index === 1 && (
          <motion.div
            style={{
              position: 'absolute'
            }}
            originX={0}
            key={1}
            initial={{ opacity: 0, x: '-10%' }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, scaleX: 0.2, x: '-20%' }}
            transition={{ duration: 0.3, delay: 0, ease: 'anticipate' }}
          >
            <IslandsType text={'30, ,O0,c2,t0,o1,b0,e0,r1'} />
          </motion.div>
        )}
        {index === 2 && (
          <motion.div
            style={{
              position: 'absolute'
            }}
            originX={0}
            key={2}
            initial={{ opacity: 0, x: '-10%' }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, scale: 0.2, x: '-20%' }}
            transition={{ duration: 0.3, delay: 0, ease: 'anticipate' }}
          >
            <IslandsType text={'O0,k1,t0,o1,b0,e0,r1,f2,e2,s1,t1'} />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

HeroText.propTypes = {
  objRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ])
}

export default HeroText
